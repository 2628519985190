import ImageHeader from "../image/stock/10.png";
import Navigation from "./Navigation";
export default function HeaderAbout() {


    return (
       <div className="w-full bg-cover bg-center bg-fixed md:px-20 h-screen" style={{ backgroundImage: `url(${ImageHeader})`}}>
           <Navigation />
       </div>
    )
}