import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import App from './App';
import {ParallaxProvider} from "react-scroll-parallax";
import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
       <ParallaxProvider>
           <BrowserRouter>
               <App />
           </BrowserRouter>
       </ParallaxProvider>
   </React.StrictMode>
);

