import HeaderAbout from "../components/HeaderAbout";
import Footer from "../components/Footer";
import DescriptionAbout from "../components/DescribtionAbout";

export default function About() {
    return (
        <div className="w-full">
            <HeaderAbout />
            <DescriptionAbout />
            <Footer />
        </div>
    )
}