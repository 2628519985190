import React from "react";
import ImageList from "../components/ImagesList";
import Footer from "../components/Footer";

export default function Home() {
    return (
        <div>
            <ImageList  />
            <Footer />
        </div>
    )
}