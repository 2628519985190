import { useParams } from "react-router-dom";
import {useEffect, useState} from "react";
import database from "../utils/database.js";
import Footer from "../components/Footer";
import {Navbar} from "flowbite-react";
import Navigation from "../components/Navigation";

export default function Detail() {
    const [fileImage, setFileImage] = useState([]);
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');
    const [currentSlide, setCurrentSlide] = useState(0);
    const { id} = useParams();

    useEffect(() => {
        const dataLink =  database().filter((item) => item.link === id)[0];
        setFileImage(dataLink.file);
        setTitle(dataLink.title);
        setLocation(dataLink.location);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextSlide();
        }, 2000);

        return () => clearInterval(intervalId);
    }, [currentSlide, fileImage]);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % fileImage.length);
    }

    const prevSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? fileImage.length - 1 : prevSlide - 1

        )
    }

    const goToSlide = (index) => {

        setCurrentSlide(index)
    }

    return (
        <>
            <div id="default-carousel" className="max-w-full" >
                <Navigation />
                <div className="relative h-[500px] overflow-hidden">

                    {
                        fileImage.map((item, index) => (
                            <div>
                                <div
                                    key={index}
                                    className={`transition duration-1000  ease-in-out absolute inset-0 opacity-0  ${
                                        index === currentSlide ? "opacity-100" : ""
                                    }`}
                                >
                                    <img
                                        src={item}
                                        alt={`Slide ${index + 1}`}
                                        className="w-full h-full object-cover"
                                    />
                                </div>

                                <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
                                    {fileImage.map((_, indicatorIndex) => (
                                        <button
                                            key={indicatorIndex}
                                            type="button"
                                            className={`w-3 h-3 rounded-full ${
                                                indicatorIndex === currentSlide
                                                    ? "bg-gray-600" // Active indicator color
                                                    : "bg-gray-300" // Inactive indicator color
                                            }`}
                                            aria-current={indicatorIndex === currentSlide ? "true" : "false"}
                                            aria-label={`Slide ${indicatorIndex + 1}`}
                                            data-carousel-slide-to={indicatorIndex}
                                            onClick={() => goToSlide(indicatorIndex)} // Handle indicator click
                                        ></button>
                                    ))}
                                </div>
                            </div>
                        ))
                    }

                    <button type="button" onClick={prevSlide}
                            className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                    >
                <span
                    className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                            <svg className="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M5 1 1 5l4 4"/>
                        </svg>
                    <span className="sr-only">Previous</span>
                </span>
                    </button>
                    <button type="button" onClick={nextSlide}
                            className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                    >
                <span
                    className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg className="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                         fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="m1 9 4-4-4-4"/>
                    </svg>
                    <span className="sr-only">Next</span>
                </span>
                    </button>
                </div>
            </div>
            <div className="h-56 w-full">
                <div className="w-3/4 p-10 mt-14 mx-auto">
                    <h1 className="text-black font-bold text-4xl">{title}</h1>
                    <p className="mt-2">{location}</p>
                </div>
            </div>

            <Footer />
        </>


    )
}