export default function DescriptionAbout() {
    return (
        <div className="col-span-12 py-40 px-10 md:px-40 mt-10 bg-white">
            <h3 className="font-bold text-black text-4xl leading-relaxed">
                At BVR Architects/Designers & Master Planners, we pride ourselves on delivering unique, handcrafted architectural services that give our valued clients mature and integrated projects that accommodate the state of the art technical and IT services that modern lifestyle and working environments demands.
            </h3>
            <ul className="space-y-3 mt-5">
                <li className="leading-relaxed mt-8">
                    BVR Architects/Designers & Master Planners primarily focuses is meeting the needs of the high-end destination/hospitality etc. sectors by offering world-class architecture services to discerning Clients throughout the tropical and semi-tropical regions of the world and beyond. Our ongoing commitment to excellent design quality ensures that we remain at the leading edge of high-end resort and luxury designs.
                </li>
                <li className="leading-relaxed mt-8">
                    Strategically located in Bali, Indonesia, our expanding portfolio includes working with brands such as Marriott, Ritz-Carlton, IHG, The Savoy, The Oberoi Group, Wyndham, Accor, Private Wealth offices, Ballast Nedam, Pension Funds and HNWI.
                </li>
                <li className="leading-relaxed mt-8">
                    Through developing, on a project-by-project basis, a strong cultural sensitivity and a careful understanding of the resident people and specific locales, our team of architects etc. can design high-quality tropical destinations and buildings. This site-specific awareness ensures delivery of the most appropriate style of architecture for the prevailing culture and climate in locations such as Australia, South-East Asia, Japan, East Africa, Russia and North/Central/South America.
                </li>
            </ul>
        </div>
    )
}