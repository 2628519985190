import {Route, Routes} from "react-router-dom";
import About from "../pages/About";
import Service from "../pages/Service";
import Home from "../pages/Home";
import Detail from "../pages/Detail";

export default function RoutesNavigation(){
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/detail/:id" element={<Detail />} />
        </Routes>
    )
}
