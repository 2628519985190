import ImageHeader from "../image/stock/7.png";
import Navigation from "./Navigation";

export default function HeaderService() {
    return (
        <div className="w-full">
            <div className="w-screen bg-fixed bg-no-repeat bg-cover bg-center  md:px-20 h-screen " style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${ImageHeader})`}}>
                <Navigation />
                <div className="h-screen w-full mx-auto">
                    <div className="text-white items-center mt-16">
                        <h1 className="mt-5 text-5xl font-bold text-center">our service</h1>
                        <p className="text-white text-2xl leading-loose font-light w-full md:w-1/2 mx-auto text-center mt-5">At BVR Architects/Designers & Master Planners, we pride ourselves on delivering unique, handcrafted architectural services that give our valued clients mature and integrated projects that accommodate the state of the art technical and IT services that modern lifestyle and working environments demands.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}