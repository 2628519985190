import HeaderService from "../components/HeaderService";
import Footer from "../components/Footer";

export default function  Service() {
    return (
        <div>
            <HeaderService />
            <Footer />
        </div>
    )
}