import React from "react";
import LogoBvr from "../image/logo/BVR ARCHITECTURE HORIZONTAL BLACK.png";
import LogoDigital from "../image/logo/Logo BVR Digital Horizontal black.png";

export default function Footer() {
    return (
        <footer className="bg-[#f0f0f0] max-w-full">
            <div className={`w-full grid grid-cols-1 col-span-2 lg:grid-cols-12 p-5 py-8 md:py-16 lg:py-24 md:px-16 lg:px-24 space-y-7`}>
                <div className={`max-w-full lg:col-span-12`}>
                    <div className={`max-w-7xl mx-auto`}>
                        <img src={LogoBvr} alt="Bvr Architecture" style={{width: "200px"}}/>
                    </div>
                </div>
                <div className={`max-w-full col-span-2 lg:col-span-12`}>
                    <div className={`max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-12 gap-5`}>
                        <div className={`col-span-4`}>
                            <h3 className="text-black font-bold text-sm leading-relaxed">About BVR Architecture</h3>
                            <p className="text-slate-600 text-xs leading-relaxed mt-3 text-justify">
                                At BVR Architects/Designers & Master Planners, we pride ourselves on delivering unique, handcrafted architectural services that give our valued clients mature and integrated projects that accommodate the state of the art technical and IT services that modern lifestyle and working environments demands.
                            </p>
                            <a href="#" className="text-gray-700 mt-5 block font-bold text-xs hover:text-black transition ease-in-out duration-300">Learn More</a>
                        </div>
                        <div className={`col-span-4`}>
                            <h3 className="text-black font-bold text-sm leading-relaxed">
                                Useful Link
                            </h3>
                            <ul className="text-slate-600 text-xs leading-loose mt-5">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <a href="/service">Service</a>
                                </li>
                                <li>
                                    <a href="/about">About</a>
                                </li>
                            </ul>
                        </div>
                        <div className={`col-span-4`}>
                            <h3 className="text-black font-bold text-sm leading-relaxed">
                                Connect with Us
                            </h3>
                            <ul className="text-slate-600 text-xs leading-loose mt-5">
                                <li>Facebook</li>
                                <li>Instagram</li>
                                <li>Mail</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`col-span-12`}>
                <div className={`max-w-4xl mx-auto`}>
                    <div className={`p-5 flex items-center justify-center space-x-5`}>
                        <p>Site Developed by</p>
                        <img src={LogoDigital} alt="logo digital" width={60} />
                        <p>©2022 All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}