import imageMandapaFirst from "../image/stock/1.png";
import imageMandapaSecond from "../image/stock/6.png";
import PadmaUbud from "../image/stock/11.png";
import PadmaLegianFirst from "../image/stock/16.png";
import PadmaLegianSecond from "../image/stock/21.png";
import AyanaResidences from "../image/stock/26.png";
import Anantara from "../image/stock/31.png";
import Cempedak from "../image/stock/36.png";
import Westin from "../image/stock/41.png";
import Samaya from "../image/stock/46.png";
import Kimpton from "../image/stock/51.png";
import Savoy from "../image/stock/56.png";
import KickingHorse from "../image/stock/61.png";
import SandalyFirst from "../image/stock/66.png";
import SandalySecond from "../image/stock/71.png";
import KickingHourseMauntainResortFirst from "../image/stock/76.png";
import KickingHourseMauntainResortSecond from "../image/stock/81.png";
import RoyalBayColwood from "../image/stock/86.png";
import RiverSideQuays from "../image/stock/90.png";
import BaliCoffeeClub from  "../image/header/20.jpeg";
import Gresik from "../image/header/21.jpg";
import MandalaLogoFirst from "../image/logo/mandapa-logo.png";
import MandalaLogoSecond from "../image/logo/mandapa-logo.png";
import PadmaUbudLogo from "../image/logo/logo-padma-ubud-white.png";
import PadmaLegianFirstLogo from "../image/logo/padma-legian-logo-white.png";
import PadmaLegianSecondLogo from "../image/logo/padma-legian-logo-white.png";
import AyanaLogoFirst from "../image/logo/ayana-logo-large.png";
import AnantaraLogoFirst from "../image/logo/anantara_uluwatu_bali_logo_white.png";
import Cempedaklogo from "../image/logo/cempedak_logo.png";
import WestinLogo from "../image/logo/westin_resort_logo_white.png";
import SemayaLogo from "../image/logo/padma-legian-logo-m.png";
import KimptonLogo from "../image/logo/kimpton-logo.png";
import SavoyLogo from "../image/logo/savoy_logo.png";
import KickingLogo from "../image/logo/logo-kicking-horse.png";
import SandalyLogoFirst from "../image/logo/sandals_logo.png";
import SandalyLogoSecond from "../image/logo/sandals_logo.png";
import KickingLogoFirst from "../image/logo/logo-kicking-horse.png";
import KickingLogoSecond from "../image/logo/logo-kicking-horse.png";
import RoyalBayColWoodLogo from "../image/logo/Live-at-Royal-Bay-Logo.png";
import RiverSideQuaysLogo from "../image/logo/logoanantara.png";
import BccLogo from "../image/logo/Bali Coffee Club Vertical White.jpg";
import BvrArchitecture from "../image/logo/Logo BVR Architecture Vertical WHITE.png";

// file image mandapa resort
import mandapa1 from "../image/stock/2.png";
import mandapa2 from "../image/stock/3.png";
import mandapa3 from "../image/stock/4.png";
import mandapa4 from "../image/stock/5.png";

// mandapa camp
import mandapaCamp1 from "../image/stock/7.png";
import mandapaCamp2 from "../image/stock/8.png";
import mandapaCamp3 from "../image/stock/9.png";
import mandapaCamp4 from "../image/stock/5.png";

// padma ubud
import padmaResortUbud1 from "../image/stock/12.png";
import padmaResortUbud2 from "../image/stock/13.png";
import padmaResortUbud3 from "../image/stock/14.png";
import padmaResortUbud4 from "../image/stock/15.png";

// padma resort
import padmaResort1 from "../image/stock/17.png";
import padmaResort2 from "../image/stock/18.png";
import padmaResort3 from "../image/stock/19.png";
import padmaResort4 from "../image/stock/20.png";

// padma legian
import padmaLegian1 from "../image/stock/22.png";
import padmaLegian2 from "../image/stock/23.png";
import padmaLegian3 from "../image/stock/24.png";
import padmaLegian4 from "../image/stock/25.png";

// ayana
import ayanaResidence1 from "../image/stock/27.png";
import ayanaResidence2 from "../image/stock/28.png";
import ayanaResidence3 from "../image/stock/29.png";
import ayanaResidence4 from "../image/stock/30.png";

// anantara
import anantaraResort1 from "../image/stock/32.png";
import anantaraResort2 from "../image/stock/33.png";
import anantaraResort3 from "../image/stock/34.png";
import anantaraResort4 from "../image/stock/35.png";

// cempedak
import cempedakResort1 from "../image/stock/37.png";
import cempedakResort2 from "../image/stock/38.png";
import cempedakResort3 from "../image/stock/39.png";
import cempedakResort4 from "../image/stock/40.png";

// westin ubud
import westinUbud1 from "../image/stock/42.png";
import westinUbud2 from "../image/stock/43.png";
import westinUbud3 from "../image/stock/44.png";
import westinUbud4 from "../image/stock/45.png";

// samaya
import samayaSeminyak1 from "../image/stock/47.png";
import samayaSeminyak2 from "../image/stock/48.png";
import samayaSeminyak3 from "../image/stock/49.png";
import samayaSeminyak4 from '../image/stock/50.png';

//kimpton
import kimtonNusaDua1 from "../image/stock/52.png";
import kimtonNusaDua2 from "../image/stock/53.png";
import kimtonNusaDua3 from "../image/stock/54.png";
import kimtonNusaDua4 from "../image/stock/55.png";

// savoy
import savoy1 from "../image/stock/57.png";
import savoy2 from "../image/stock/58.png";
import savoy3 from "../image/stock/59.png";
import savoy4 from "../image/stock/60.png";

// kicking horse mountain
import kickingHorseMountain1 from "../image/stock/62.png";
import kickingHorseMountain2 from "../image/stock/63.png";
import kickingHorseMountain3 from "../image/stock/64.png";
import kickingHorseMountain4 from "../image/stock/65.png";

// sandals emerland
import sandalsEmerland1 from "../image/stock/67.png";
import sandalsEmerland2 from "../image/stock/68.png";
import sandalsEmerland3 from "../image/stock/69.png";
import sandalsEmerland4 from "../image/stock/70.png";

// sandals four seasons
import sandalsForSeasons1 from "../image/stock/72.png";
import sandalsForSeasons2 from "../image/stock/73.png";
import sandalsForSeasons3 from "../image/stock/74.png";
import sandalsForSeasons4 from "../image/stock/75.png";

// kicking horse golf course
import kickingHorseGolf1 from "../image/stock/77.png";
import kickingHorseGolf2 from "../image/stock/78.png";
import kickingHorseGolf3 from "../image/stock/79.png";
import kickingHorseGolf4 from "../image/stock/80.png";

// kicking eagle
import kickingEagle1 from "../image/stock/82.png";
import kickingEagle2 from "../image/stock/83.png";
import kickingEagle3 from "../image/stock/84.png";
import kickingEagle4 from "../image/stock/85.png";

// royal bay colwood
import royalBay1 from "../image/stock/87.png";
import royalBay2 from "../image/stock/88.png";
import royalBay3 from "../image/stock/89.png";
import royalBay4 from "../image/stock/90.png";

// river side
import riverSide1 from "../image/stock/92.png";
import riverSide2 from "../image/stock/93.png";
import riverSide3 from "../image/stock/94.png";
import riverSide4 from "../image/stock/95.png";


export default function  database(){
    return [
        {
            "image": imageMandapaFirst,
            "logo": MandalaLogoFirst,
            'link': 'mandapa-resort',
            'file': [mandapa1, mandapa2, mandapa3, mandapa4],
            'title': 'Mandapa Resort',
            'location': 'Ubud, Bali - Indonesia'
        },
        {
            "image": imageMandapaSecond,
            "logo": MandalaLogoSecond,
            'link': 'mandapa-camp',
            'file': [mandapaCamp1, mandapaCamp2, mandapaCamp3, mandapaCamp4],
            'title': 'Mandapa Kids Camp',
            'location': 'Ubud, Bali - Indonesia'
        },
        {
            "image": PadmaUbud,
            "logo": PadmaUbudLogo,
            'link': 'padma-ubud',
            'file': [padmaResortUbud1, padmaResortUbud2, padmaResortUbud3, padmaResortUbud4],
            'title': 'Padma Resort Legian | New Lobby, Restaurant, Spa',
            'location': 'Legian, Bali - Indonesia'
        },
        {
            "image": PadmaLegianFirst,
            "logo": PadmaLegianFirstLogo,
            'link': 'padma-resort',
            'file': [padmaResort1, padmaResort2, padmaResort3, padmaResort4],
            'title': 'Padma Resort Legian | New Lobby, Restaurant, Spa',
            'location': 'Legian, Bali - Indonesia'
        },
        {
            "image": PadmaLegianSecond,
            "logo": PadmaLegianSecondLogo,
            'link': 'padma-legian',
            'file': [padmaLegian1, padmaLegian2, padmaLegian3, padmaLegian4],
            'title': 'Padma Resort Legian | Guest Room & Lagoon Pool',
            'location': 'Legian, Bali - Indonesia'
        },
        {
            "image": AyanaResidences,
            "logo": AyanaLogoFirst,
            'link': 'ayana',
            'file': [ayanaResidence1, ayanaResidence2, ayanaResidence3, ayanaResidence4],
            'title': 'Ayana Residence',
            'location': 'Jimbaran, Bali - Indonesia'
        },
        {
            "image": Anantara,
            "logo": AnantaraLogoFirst,
            'link': 'anantara',
            'file':[anantaraResort1, anantaraResort2, anantaraResort3, anantaraResort4],
            'title': 'Anantara Resort',
            'location': 'Uluwatu, Bali - Indonesia'
        },
        {
            "image": Cempedak,
            "logo": Cempedaklogo,
            'link': 'cempedak',
            'file': [cempedakResort1, cempedakResort2, cempedakResort3, cempedakResort4],
            'title': 'Cempedak Resort',
            'location': 'Cempedak Island Indonesia'
        },
        {
            "image": Westin,
            "logo": WestinLogo,
            'link': 'westin',
            'file': [westinUbud1, westinUbud2, westinUbud3, westinUbud4],
            'title': 'Westin Ubud',
            'location': 'Ubud, Bali - Indonesia'
        },
        {
            "image": Samaya,
            "logo": SemayaLogo,
            'link': 'samaya',
            'file': [samayaSeminyak1, samayaSeminyak2, samayaSeminyak3, samayaSeminyak4],
            'title': 'The Samaya Seminyak',
            'location': 'Seminyak, Bali - Indonesia'
        },
        {
            "image": Kimpton,
            "logo": KimptonLogo,
            'link': 'kimton',
            'file': [kimtonNusaDua1, kimtonNusaDua2, kimtonNusaDua3, kimtonNusaDua4],
            'title': 'Kimpton Nusa Dua',
            'location': 'Nusa Dua, Bali - Indonesia'
        },
        {
            "image": Savoy,
            "logo": SavoyLogo,
            'link': 'savoy',
            'file': [savoy1, savoy2, savoy3, savoy4],
            'title': 'Savoy Resort & Spa',
            'location': 'Mahe Island, Seychelles'
        },
        {
            "image": KickingHorse,
            "logo": KickingLogo,
            'link': 'kick-horse',
            'file': [kickingHorseMountain1, kickingHorseMountain2, kickingHorseMountain3, kickingHorseMountain4],
            'title': 'Kicking Horse Mountain Resort',
            'location': 'Kicking Horse Trail, Golden, BC'
        },
        {
            "image": SandalyFirst,
            "logo": SandalyLogoFirst,
            'link': 'sandals-emerland',
            'file': [sandalsEmerland1, sandalsEmerland2, sandalsEmerland3, sandalsEmerland4],
            'title': 'Sandals Emerland Bay Resort',
            'location': 'Great Exuma, Bahamas'
        },
        {
            "image": SandalySecond,
            "logo": SandalyLogoSecond,
            'link': 'sandals-seasons',
            'file': [sandalsForSeasons1, sandalsForSeasons2, sandalsForSeasons3, sandalsForSeasons4],
            'title': 'Four Seasons Hotel Papagayo',
            'location': 'Papagayo, Costa Rica'
        },
        {
            "image": KickingHourseMauntainResortFirst,
            "logo": KickingLogoFirst,
            'link': 'kicking-horse-golf',
            'file': [kickingHorseGolf1, kickingHorseGolf2,kickingHorseGolf3, kickingHorseGolf4],
            'title': 'Kicking Horse Golf Course',
            'location': 'Kicking Horse Trail, Golden, BC'
        },
        {
            "image": KickingHourseMauntainResortSecond,
            "logo": KickingLogoSecond,
            'link': 'eagle-eye-restaurant',
            'file': [kickingEagle1, kickingEagle2, kickingEagle3, kickingEagle4],
            'title': 'Eagle\'s Eye Restaurant',
            'location': 'Kicking Horse Trail, Golden, BC'
        },
        {
            "image": RoyalBayColwood,
            "logo": RoyalBayColWoodLogo,
            'link': 'royal-bay-colwood',
            'file': [royalBay1, royalBay2, royalBay3, royalBay4],
            'title': 'Royal Bay Colwood',
            'location': 'Colwood, BC, Canada'
        },
        {
            "image": RiverSideQuays,
            "logo": RiverSideQuaysLogo,
            'link': 'river-side-quays',
            'file': [riverSide1, riverSide2, riverSide3, riverSide4],
            'title': 'River Side Quays Calgary',
            'location': 'Calgary, Canada'
        },
        {
            "image": BaliCoffeeClub,
            "logo": BccLogo,
            'link': 'bali-coffee-club',
            'file': [riverSide1, riverSide2, riverSide3, riverSide4],
            'title': 'Bali Coffee Club',
            'location': 'Bali, Indonesia'
        },
        {
            "image":Gresik,
            "logo": BvrArchitecture,
            'link': 'gresik',
            'file': [riverSide1, riverSide2, riverSide3, riverSide4],
            'title': 'Gresik',
            'location': 'Gresik, Indonesia'
        },
    ]
}