import database from "../utils/database.js";
import Logo from "../image/logo/BVR ARCHITECTURE HORIZONTAL WHITE.png";
import {Link} from "react-router-dom";
import {useState} from "react";


export default function ImageList() {
    const [open, setOpen] = useState(false);

    const handleButton = () => {
        setOpen(!open)
    }

    return (
        database().map(item => (
            <>
                <div className="w-full relative bg-cover bg-center h-screen" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.10)),url(${item.image})`, backgroundAttachment: 'fixed' }}>
                    <div className="w-11/12 mx-auto flex justify-between">
                        <img src={Logo} alt="logo bvr" width={200} className="mt-3 fixed"/>
                        <div className="mt-3 fixed right-24">
                            <button data-collapse-toggle="navbar-hamburger" type="button" onClick={handleButton}
                                    className="inline-flex items-center justify-center p-2 w-10 h-10 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                    aria-controls="navbar-hamburger" aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 17 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M1 1h15M1 7h15M1 13h15"/>
                                </svg>
                            </button>
                        </div>
                        {
                            open && (
                                <div className="flex fixed font-medium mt-4 rounded-lg scroll-m-0 z-30 top-10 right-32" >
                                    <ul className="w-44 bg-white rounded-md inline-block ">
                                        <li>
                                            <Link to="/"
                                                  className={`block py-2 pl-3 pr-4 ${window.location.pathname === '/' ? "text-red-700": "text-gray-900"} rounded`}
                                                  aria-current="page">Home</Link>
                                        </li>
                                        <li>
                                            <Link to="/about"
                                                  className={`block py-2 pl-3 pr-4 ${window.location.pathname === '/about' ? "text-red-700": "text-gray-900"} rounded`}
                                            >Service</Link>
                                        </li>
                                        <li>
                                            <Link to="/service"
                                                  className={`block py-2 pl-3 pr-4 ${window.location.pathname === '/service' ? "text-red-700": "text-gray-900"} rounded`}
                                            >About Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            )
                        }
                    </div>
                    <div className="flex flex-col items-center justify-center h-screen"  >
                        <div className="gap-5 flex items-center justify-center">

                            {
                                item.link === 'river-side-quays' ?<p className="text-4xl text-white">Riverside Quays</p>:  <img src={`${item.logo}`} alt="logo" className="max-h-40 lg:max-h-52"/>
                            }
                        </div>
                        <div className="text-white border items-center justify-center hover:bg-black p-2 mt-3 text-xl">
                            <Link to={`detail/${item.link}`}>View More</Link>
                        </div>
                    </div>

                </div>
            </>
        ))
    )
}