import Logo from "../image/logo/BVR ARCHITECTURE HORIZONTAL WHITE.png";
import {Link} from "react-router-dom";
import {useState} from "react";

export default function Navigation() {
    const [open, setOpen] = useState(false);

    const handleButton = () => {
        setOpen(!open)
    }

    return (
        <nav className="wborder-gray-200 dark:border-gray-700 relative">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
               <Link to="/">
                   <img src={Logo} alt="logo bvr" width={200} className="mt-3"/>
               </Link>
                <button data-collapse-toggle="navbar-hamburger" type="button" onClick={handleButton}
                        className="inline-flex items-center justify-center p-2 w-10 h-10 ml-3 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        aria-controls="navbar-hamburger" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
            </div>
            {
                open && (
                    <div className="flex transition duration-1000 absolute font-medium rounded-lg top-20 right-20" >
                        <ul className="w-44 border bg-white rounded-md inline-block z-30">
                            <li>
                                <Link to="/"
                                      className={`block py-2 pl-3 pr-4 ${window.location.pathname === '/' ? "text-red-700": "text-gray-900"} rounded`}
                                      aria-current="page">Home</Link>
                            </li>
                            <li>
                                <Link to="/about"
                                      className={`block py-2 pl-3 pr-4 ${window.location.pathname === '/about' ? "text-red-700": "text-gray-900"} rounded`}
                                >About</Link>
                            </li>
                            <li>
                                <Link to="/service"
                                      className={`block py-2 pl-3 pr-4 ${window.location.pathname === '/service' ? "text-red-700": "text-gray-900"} rounded`}
                                >Service</Link>
                            </li>
                        </ul>
                    </div>
                )
            }
        </nav>
    )
}